export const environment = {
  production: true,
  TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID: 1,
  TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID: 2,
  TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID: 3,
  TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID: 4,
  TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID: 5,
  REEMBOLSO_URL: 'https://d0skk0oog448c8wwo04kws48.is-on.cloud/#/',
  // API_URL: 'https://api.elevediagnostico.com.br/api/v1',
  // API_URL: 'https://v2hbbfjmtp.us-east-1.awsapprunner.com/api/v1',
  API_URL: 'https://fo0ok080ow4o4gkkos8g4ocg.is-on.cloud/api/v1',
};

