import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HealthInsurancePlanModel } from 'src/app/core/models/health-insurance-plan.model';
import { HealthInsuranceModel } from 'src/app/core/models/health-insurance.model';
import { PatientModel } from 'src/app/core/models/patient.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { BrapiService } from 'src/app/core/services/brapi.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { ProfessionsService } from '../../../../../core/services/professions.service';
import { ProfessionalsService } from '../../../../../core/services/professionals.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HealthInsurancePatientModel } from 'src/app/core/models/health-insurance-patient';
import { OptionsSelectModel } from 'src/app/shared/models/option';

@Component({
  selector: 'app-patient-edit-personal',
  templateUrl: './patient-edit-personal.component.html',
  styleUrls: ['./patient-edit-personal.component.scss'],
})
export class PatientEditPersonalComponent implements OnInit {
  addressFormGroup: FormGroup = this.fb.group({
    zipcode: [],
    address: [],
    number: [],
    complement: [],
    neighborhood: [],
    city: [],
    state: [],
  });

  formGroup: FormGroup = this.fb.group({
    id: [],
    name: [null, [Validators.required]],
    display_name: [],
    gender: [],
    birthdate: [],
    email: [],
    document_number: [],
    marital_status: [],
    profession: [],
    tags: [],
    phone_numbers: this.fb.array([]),
    health_insurances: this.fb.array([]),
    mother_name: [],
    father_name: [],
    address: this.addressFormGroup,
    clinic: [],
    clinics: [],
    professional: [],
    ad_origin: [],
    notes: [],
  });

  recommendationForm = this.fb.group({
    type: ['clinic'],
  });

  submitted = false;
  searchZipcode: boolean = false;

  imageFile!: File | null;
  changedImage: boolean = false;

  id!: number;

  professionsList!: OptionsSelectModel[];
  clinicsList!: OptionsSelectModel[];
  professionalsList!: OptionsSelectModel[];
  allProfessionals: OptionsSelectModel[] = [];
  healthInsurancesList!: OptionsSelectModel[];
  healthInsurancePlans: OptionsSelectModel[] = [];
  patientTagsList!: OptionsSelectModel[];
  phonesList!: PhoneModel[];
  existingPatientData: PatientModel = {};
  fetchedPatientData: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = 'Desativar';
  submitButton?: String = 'Salvar';
  isToConnect?: boolean = false;

  button: boolean = true;

  isClinicStaff = JSON.parse(localStorage.getItem('isClinicStaff')!);
  userAccessClinicId = JSON.parse(localStorage.getItem('userAccessClinicId')!);

  adOriginList: OptionsSelectModel[] = [
    { label: 'Instagram', value: 'INSTAGRAM' },
    { label: 'Youtube', value: 'YOU_TUBE' },
    { label: 'Site', value: 'SITE' },
  ];

  phoneTypeList: OptionsSelectModel[] = [
    { label: 'Whatsapp', value: 'WHATSAPP' },
    { label: 'Comercial', value: 'COMERCIAL' },
    { label: 'Residencial', value: 'RESIDENTIAL' },
  ];

  maritalStatusList: OptionsSelectModel[] = [
    { value: 'MARRIED', label: 'Casado' },
    { value: 'SINGLE', label: 'Solteiro' },
    { value: 'DIVORCED', label: 'Divorciado' },
    { value: 'WIDOWED', label: 'Viúvo' },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private professionService: ProfessionsService,
    private professionalService: ProfessionalsService,
    private registersService: RegistersService,
    private clinicsService: ClinicsService,
    private brApiService: BrapiService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toast: ToastrService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    console.log('PatientEditPersonalComponent.constructor');
  }

  ngOnInit(): void {
    console.log('PatientEditPersonalComponent.ngOnInit');
    this.addHealthInsurance();
    this.addPhoneNumber();
    this.activatedRoute.parent?.params.subscribe((params) => {
      this.id = params['id'];
      console.log(this.id);
      this.initializeForm();
    });
  }

  get loadingPage(): boolean {
    let fetchLists: boolean =
      this.clinicsList &&
      this.professionalsList &&
      this.professionsList &&
      this.healthInsurancesList &&
      this.patientTagsList
        ? true
        : false;
    if (fetchLists && this.fetchedPatientData) {
      return false;
    }
    return true;
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/patients']);
  }

  patientEdit() {
    (this.phoneNumberForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null)
        this.phoneNumberForms.at(index)?.get('id')?.setValue(undefined);
    });
    (this.healthInsuranceForms?.value as HealthInsurancePatientModel[]).forEach(
      (health, index) => {
        if (health.health_insurance_plan == null)
          this.healthInsuranceForms.removeAt(index);
        if (health.id == null)
          this.healthInsuranceForms.at(index)?.get('id')?.setValue(undefined);
      }
    );
    let patientData = this.formGroup.getRawValue() as PatientModel;
    patientData.is_active = true;
    if (this.changedImage)
      this.registersService
        .uploadPatientImage(this.imageFile!, patientData.id!)
        .subscribe(
          (response) => {},
          (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submitting = false;
          }
        );
    this.registersService.patientEdit(patientData).subscribe(
      (response) => {
        if (!this.isToConnect) {
          this.toast.success('Paciente alterado com sucesso', 'Sucesso');
        }
        this.router.navigate(['/dashboard/patients/']);
      },
      (errorResponse: HttpErrorResponse) => {
        this.submitting = false;
        this.mapErrorResponse(errorResponse);
      }
    );
  }

  connectPatient() {
    let data = { clinic_id: this.userAccessClinicId };
    this.registersService
      .connectPatient(this.existingPatientData, data)
      .subscribe((response) => {
        this.toast.success('Paciente conectado com sucesso', 'Sucesso');
        this.formGroup.removeControl('clinics');
        this.patientEdit();
      });
  }

  revokePatient() {
    let data = { clinic_id: this.userAccessClinicId };
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.text =
      'Deseja remover este paciente desta clinica ?';
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService
          .revokePatient(this.existingPatientData, data)
          .subscribe((response) => {
            this.toast.success(
              'Paciente foi removido desta clínica',
              'Sucesso'
            );
            this.router.navigate(['/dashboard/patients/']);
          });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;
    if (this.isToConnect) {
      this.connectPatient();
    } else {
      this.patientEdit();
    }
  }

  deactivatePatient() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.text = 'Deseja desativar este paciente?';
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService.patientRemove(this.id).subscribe(
          () => {
            this.toast.success('Paciente removido com sucesso', 'Sucesso');
            this.router.navigate(['/dashboard/patients/']);
            this.submittingRemove = false;
          },
          (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submittingRemove = false;
          }
        );
      } else {
        this.submittingRemove = false;
      }
    });
  }

  removeHandler() {
    this.submittingRemove = true;
    if (this.isClinicStaff) {
      this.deactivatePatient();
    } else {
      this.revokePatient();
    }
  }

  fetchProfessionsList() {
    this.professionService.listAll().subscribe((response) => {
      this.professionsList = response.map((item) => {
        return {
          label: item.name,
          value: item.name,
        } as OptionsSelectModel;
      });
    });
  }

  fetchClinicsList() {
    this.clinicsService.listAll().subscribe((response) => {
      this.clinicsList = response.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
          item: item,
        } as OptionsSelectModel;
      });
    });
  }

  fetchProfessionalsList() {
    this.professionalService.listAll().subscribe((response) => {
      this.allProfessionals = response.map((item) => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString(),
        } as OptionsSelectModel;
      });
      this.professionalsList = this.allProfessionals;
    });
  }

  fetchHealthInsurancesList() {
    this.registersService.listAllHealthInsurances().subscribe((response) => {
      this.healthInsurancesList = response.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
          item: item,
        } as OptionsSelectModel;
      });
    });
  }

  fetchPatientTags() {
    this.registersService.listAllPatientTags().subscribe((response) => {
      this.patientTagsList = response.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        } as OptionsSelectModel;
      });
    });
  }

  fetchPatientExistingData() {
    this.registersService.patientGet(this.id).subscribe(
      (response) => {
        this.existingPatientData = response;
        this.healthInsuranceForms.clear();
        this.phoneNumberForms.clear();
        response.health_insurances!.forEach(() => this.addHealthInsurance());
        response.phone_numbers!.forEach(() => this.addPhoneNumber());
        if (this.existingPatientData.ad_origin) {
          this.recommendationForm.get('type')?.setValue('publicity');
        }
        this.formGroup.patchValue(response);
        this.treatFormAndButtons();
        this.fetchedPatientData = true;
      },
      (error) => {
        this.mapErrorResponse(error);
      }
    );
  }

  treatFormAndButtons() {
    if (this.isClinicStaff) {
      if (this.existingPatientData.is_active) {
        this.button = true;
        this.removeButton = 'Desativar';
        this.submitButton = 'Salvar';
      } else {
        this.button = false;
        this.submitButton = 'Reativar';
        this.formGroup.disable();
      }
    } else if (
      this.existingPatientData.clinics?.includes(this.userAccessClinicId)
    ) {
      this.submitButton = 'Salvar';
      this.removeButton = 'Revogar';
      this.button = true;
    } else {
      this.isToConnect = true;
      this.submitButton = 'Salvar/Conectar';
      this.button = false;
    }
  }

  searchPatient(document_number: any) {
    if (document_number) {
      this.fetchedPatientData = false;
      this.registersService.searchPatient(document_number).subscribe(
        (response) => {
          if (response) {
            this.router.navigate([
              `dashboard/patients/edit/${response.id}/personal/`,
            ]);
          } else {
            this.fetchedPatientData = true;
          }
        },
        (error) => {
          this.fetchedPatientData = true;
        }
      );
    }
  }

  initializeForm() {
    this.fetchClinicsList();
    this.fetchProfessionsList();
    this.fetchProfessionalsList();
    this.fetchHealthInsurancesList();
    this.fetchPatientTags();
    this.fetchPatientExistingData();
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true;
  }

  handleZipCodeChange(ev: any) {
    this.searchZipcode = true;
    let zipcode = this.addressFormGroup.get('zipcode')?.value;
    this.addressFormGroup.disable();
    this.brApiService.getAddressByZipCode(zipcode).subscribe(
      (response) => {
        this.addressFormGroup.patchValue({
          address: response.street,
          neighborhood: response.neighborhood,
          city: response.city,
          state: response.state,
        });
        this.searchZipcode = false;
        this.addressFormGroup.enable();
      },
      (error: HttpErrorResponse) => {
        this.addressFormGroup
          .get('zipcode')
          ?.setErrors({ response: 'Cep não encontrado' });
        this.searchZipcode = false;
        this.addressFormGroup.enable();
      }
    );
  }

  changeHealthInsurancePlans(healthInsurance: HealthInsuranceModel) {
    this.healthInsurancePlans =
      healthInsurance.plans?.map((item) => {
        return {
          label: item.name,
          value: item.id!.toString(),
        } as OptionsSelectModel;
      }) || [];
  }

  getHealthInsurancePlansFrom(formGroupIndex: number): OptionsSelectModel[] {
    let formGroup = this.healthInsuranceForms.controls[
      formGroupIndex
    ] as FormGroup;
    let healthInsuranceId = formGroup.get('health_insurance_id')?.value;
    if (this.healthInsurancesList) {
      let healthInsurance = this.healthInsurancesList.find(
        (healthInsurance) => healthInsurance.value == healthInsuranceId
      )?.item;
      if (healthInsurance) {
        let plans =
          healthInsurance.plans?.map((item: HealthInsurancePlanModel) => {
            return {
              label: item.name,
              value: item.id?.toString(),
            } as OptionsSelectModel;
          }) || [];
        return plans;
      }
    }
    return [];
  }

  getProfessionalsFromClinic(clinic: any) {
    let professional = this.formGroup.get('professional')?.value;
    if (!clinic) {
      this.professionalsList = [];
      this.formGroup.get('professional')?.reset();
    } else {
      clinic = this.clinicsList.find(
        (item) => item.value == clinic.toString()
      )?.item;
      let professionalsList = this.allProfessionals.filter(
        (item) => clinic.professionals.indexOf(Number(item.value)) != -1
      );
      this.professionalsList = professionalsList;
      let searchProfessional = professionalsList.find(
        (item) => item.value == professional
      );
      if (!searchProfessional) {
        this.formGroup.get('professional')?.reset();
      }
    }
  }

  get healthInsuranceForms() {
    return this.formGroup.get('health_insurances') as FormArray;
  }

  getHealthInsuranceForm(index: number) {
    return this.healthInsuranceForms.controls[index] as FormGroup;
  }

  get phoneNumberForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumberForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: [],
      type: ['WHATSAPP'],
      is_active: [true],
    });

    this.phoneNumberForms.push(phoneNumberFormGroup);
  }

  addHealthInsurance() {
    let healthInsuranceFormGroup = this.fb.group({
      id: [],
      health_insurance_id: [],
      health_insurance_plan: [],
      number: [],
      is_active: [true],
    });
    this.healthInsuranceForms.push(healthInsuranceFormGroup);
  }

  removeHealthInsurance(at: number) {
    let formGroup = this.healthInsuranceForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.healthInsuranceForms.removeAt(at);
    }
  }

  removePhoneNumber(at: number) {
    let formGroup = this.phoneNumberForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumberForms.removeAt(at);
    }
  }

  healthInsuranceIsActive(index: number) {
    let formGroup = this.healthInsuranceForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  phoneNumberIsActive(index: number) {
    let formGroup = this.phoneNumberForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  resetClinic() {
    this.formGroup.get('clinic')?.reset();
    this.formGroup.get('professional')?.reset();
  }

  resetAds() {
    this.formGroup.get('ad_origin')?.reset();
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error['detail']) {
      this.toast.error(errorResponse.error['detail'], 'Erro');
    } else {
      this.setFormErrors(errorResponse);
    }
    this.document.getElementById('main-container')?.scroll({ top: 0 });
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'name',
      'display_name',
      'gender',
      'birthdate',
      'email',
      'document_number',
      'marital_status',
      'profession',
      'tags',
      'health_insurances',
      'phone_numbers',
      'mother_name',
      'father_name',
      'clinic',
      'professional',
      'ad_origin',
    ];
    errNames.forEach((name) => {
      if (errorResponse.error[name])
        this.formGroup
          .get(name)
          ?.setErrors({ response: errorResponse.error[name] });
    });

    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      phone_numbers.map((res, index) => {
        let formGroupPhone = this.phoneNumberForms.controls[index] as FormGroup;
        if (res['phone_number'])
          formGroupPhone
            .get('phone_number')
            ?.setErrors({ response: res['phone_number'] });
        else {
          if (res['country_code'])
            formGroupPhone
              .get('phone_number')
              ?.setErrors({ response: res['country_code'] });
          else {
            if (res['type'])
              formGroupPhone
                .get('phone_number')
                ?.setErrors({ response: res['type'] });
          }
        }
      });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.formGroup.dirty;
  }
}
